import ErrorComponent from '@/components/ErrorComponent';

export enum PageErrorType {
  NotFound = 'NotFound',
  InternalServerError = 'InternalServerError',
}

type Props = {
  errorType?: PageErrorType;
};

const PageError = ({ errorType = PageErrorType.InternalServerError }: Props) => {
  const errorProps = {
    [PageErrorType.InternalServerError]: {
      title: '일시적인 오류가 발생했어요.',
      description: (
        <>
          일시적으로 화면을 불러올 수 없어요.
          <br />
          잠시 후 다시 시도해주세요.
        </>
      ),
      action: {
        label: '새로고침',
        onClick: () => {
          window.location.reload();
        },
      },
    },
    [PageErrorType.NotFound]: {
      title: '페이지를 찾을 수 없어요.',
      description: (
        <>
          페이지의 주소가 잘못되었거나 변경 혹은 삭제되어 요청하신 페이지를 찾을 수 없어요.
          <br />
          서비스 이용에 불편을 드려 죄송해요.
        </>
      ),
      action: {
        label: '홈으로 가기',
        onClick: () => {
          window.location.href = '/';
        },
      },
    },
  }[errorType];

  return (
    <div className="mx-5 md:mx-auto pb-14 h-full flex items-center justify-center">
      <ErrorComponent {...errorProps} />
    </div>
  );
};

export default PageError;
