/**
 * @generated SignedSource<<51e9ba3bb7c342a96d053f6be74222d3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RequestBigPictureImageUploadInput = {
  contentType: string;
  filepath: string;
  height: number;
  type: string;
  width: number;
};
export type bigpictureRequestUploadMutation$variables = {
  input: RequestBigPictureImageUploadInput;
};
export type bigpictureRequestUploadMutation$data = {
  readonly requestBigPictureImageUpload: {
    readonly __typename: "BigPictureImageUploadInfo";
    readonly originalId: string;
    readonly thumbnailUrl: string;
    readonly uploadUrl: string;
  } | {
    readonly __typename: "ToastError";
    readonly toastError: {
      readonly message: string;
    };
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type bigpictureRequestUploadMutation = {
  response: bigpictureRequestUploadMutation$data;
  variables: bigpictureRequestUploadMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "requestBigPictureImageUpload",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "originalId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "uploadUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "thumbnailUrl",
            "storageKey": null
          }
        ],
        "type": "BigPictureImageUploadInfo",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ToastErrorPayload",
            "kind": "LinkedField",
            "name": "toastError",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "ToastError",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "bigpictureRequestUploadMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "bigpictureRequestUploadMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "84d0ac027bafb56e98d6cffb0552a6a8",
    "id": null,
    "metadata": {},
    "name": "bigpictureRequestUploadMutation",
    "operationKind": "mutation",
    "text": "mutation bigpictureRequestUploadMutation(\n  $input: RequestBigPictureImageUploadInput!\n) {\n  requestBigPictureImageUpload(input: $input) {\n    __typename\n    ... on BigPictureImageUploadInfo {\n      originalId\n      uploadUrl\n      thumbnailUrl\n    }\n    ... on ToastError {\n      toastError {\n        message\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e0e661e5c6f4c9baf36cee0da3dd270c";

export default node;
