import InfiniteLoading from '@/components/InfinteLoading';
import { SearchResultWarning } from '@/components/SearchResult';
import { escapeStringRegexp, getJibunAddress } from '@daangn/realty-sdk';
import { highlightText } from '@daangn/realty-react/utils';
import { Spinner } from '@daangn/sprout-components-spinner';
import useCheckWritableAddress, { CorFormState } from 'hooks/useCheckWritableAddress';
import useSearchCorAddress from 'hooks/useSearchCorAddress';
import { Fragment, useMemo } from 'react';
import { SalesTypeEnum } from 'types/schemaEnums';
import { Badge } from '@daangn/carotene';

type Props = {
  query: string;
  onSelectItem: (address: CorFormState) => void;
  selectedSalesType?: SalesTypeEnum;
};

const removeDetailFromAddress = (address: string) => {
  const parsedAddress = address.replace(/제(\d+(동|호|층))/g, '$1').replace(/\[.+\]$/g, '');

  return parsedAddress;
};

const SearchCorAddressResult = ({ query, onSelectItem, selectedSalesType }: Props) => {
  const { data, hasNext, loadNext } = useSearchCorAddress({
    query,
    salesType: selectedSalesType,
  });
  const boldText = useMemo(() => {
    return new RegExp(`(${escapeStringRegexp(query)})`, 'gi');
  }, [query]);
  const { isChecking, handleAddressItemClick } = useCheckWritableAddress({
    onSubmit: onSelectItem,
  });

  const items = data.searchCorAddress.edges;

  if (items.length === 0) {
    return (
      <SearchResultWarning>
        검색 결과가 없어요.
        <br />
        주소를 확인해주세요.
      </SearchResultWarning>
    );
  }

  return (
    <div className="relative w-full">
      <div className="flex flex-col overflow-auto h-full">
        {items.map(({ node }, i) => {
          const jibunAddress = getJibunAddress(node.address);
          const parsedAddress = removeDetailFromAddress(node.address);

          return (
            <Fragment key={node.corRealtyId}>
              <div
                className="flex flex-col gap-1 body-small-default px-4 py-3"
                onClick={() => handleAddressItemClick(node)}
              >
                <p>
                  {highlightText({
                    fullText: parsedAddress,
                    boldText,
                    tag: 'span',
                    className: 'text-accent',
                  })}
                </p>
                {jibunAddress && (
                  <div className="flex gap-1 items-center">
                    <Badge size="small" tone="neutral">
                      지 번
                    </Badge>
                    <div>{jibunAddress}</div>
                  </div>
                )}
              </div>
              {i !== items.length - 1 && <div className="border-b border-bg-neutralHover" />}
            </Fragment>
          );
        })}
        <div className="px-4 py-3 empty:hidden">
          <InfiniteLoading hasNext={hasNext} onLoadMore={() => loadNext(20)} />
        </div>
      </div>
      {isChecking && (
        <div className="absolute top-0 left-0 right-0 bottom-0 flex flex-col gap-2 items-center justify-center bg-white opacity-50">
          <Spinner />
          <p>주소를 확인중이에요.</p>
        </div>
      )}
    </div>
  );
};

export default SearchCorAddressResult;
