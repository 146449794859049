/**
 * @generated SignedSource<<80959805e8d9af31a599a40de8cbb2f3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SalesTypeEnum = "APART" | "ETC" | "OFFICETEL" | "OPEN_ONE_ROOM" | "SPLIT_ONE_ROOM" | "STORE" | "TWO_ROOM";
export type SearchAddressesCorInput = {
  dong?: string | null | undefined;
  ho?: string | null | undefined;
  query: string;
  salesType?: SalesTypeEnum | null | undefined;
};
export type useSearchCorAddressResultQuery$variables = {
  count?: number | null | undefined;
  cursor?: string | null | undefined;
  input: SearchAddressesCorInput;
};
export type useSearchCorAddressResultQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useSearchCorAddressResult_query">;
};
export type useSearchCorAddressResultQuery = {
  response: useSearchCorAddressResultQuery$data;
  variables: useSearchCorAddressResultQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": 9,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v3 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "data",
    "variableName": "input"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useSearchCorAddressResultQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          {
            "kind": "Variable",
            "name": "cursor",
            "variableName": "cursor"
          },
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "kind": "FragmentSpread",
        "name": "useSearchCorAddressResult_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "useSearchCorAddressResultQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "AddressCorConnection",
        "kind": "LinkedField",
        "name": "searchCorAddress",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AddressCorEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CorAddress",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "address",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "corRealtyId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "kind",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isRentalHouse",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "filters": [
          "data"
        ],
        "handle": "connection",
        "key": "useSearchCorAddressResult_searchCorAddress",
        "kind": "LinkedHandle",
        "name": "searchCorAddress"
      }
    ]
  },
  "params": {
    "cacheID": "72fd3ff9b4bff4d8fcbd466182215d07",
    "id": null,
    "metadata": {},
    "name": "useSearchCorAddressResultQuery",
    "operationKind": "query",
    "text": "query useSearchCorAddressResultQuery(\n  $cursor: String\n  $count: Int = 9\n  $input: SearchAddressesCorInput!\n) {\n  ...useSearchCorAddressResult_query_YCAiB\n}\n\nfragment useSearchCorAddressResult_query_YCAiB on Query {\n  searchCorAddress(first: $count, after: $cursor, data: $input) {\n    edges {\n      node {\n        address\n        corRealtyId\n        kind\n        isRentalHouse\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "de5dcb89fb771bc9c06b159af0c51de9";

export default node;
