import type { ArticleFormType } from '@/scheme/article';
import CommonStorage from '@/utils/storage';
import { omit } from 'lodash-es';
import type { DeepPartial } from 'react-hook-form';

enum LocalStorageKey {
  BrokerInfo = 'brokerInfo',
  TempArticle = 'tempArticleV2',
  ActionHistory = 'actionHistory',
  ActionCount = 'actionCountV2',
}

export enum ActionHistoryKey {
  SeenAIConversionStartStep = 'seenAIConversionStartStep',
  SeenRealtyHomeBanner = 'seenRealtyHomeBanner',
  ClickedBrokerFaqBanner = 'clickedBrokerFaqBanner',
  AgreedTelephoneTerms = 'agreedTelephoneTerms',
}

export enum ActionCountKey {
  BrokerFaqBannerImpression = 'brokerFaqBannerImpression',
}

class LocalStorage extends CommonStorage<typeof LocalStorageKey> {
  constructor() {
    super('realty-ceo', window.localStorage);
  }

  setTempArticle(value: DeepPartial<ArticleFormType>) {
    this.setItem(LocalStorageKey.TempArticle, value);
  }

  getTempArticle() {
    return this.getItem<DeepPartial<ArticleFormType>>(LocalStorageKey.TempArticle);
  }

  clearTempArticle() {
    this.removeItem(LocalStorageKey.TempArticle);
  }

  getActionHistories() {
    return this.getItem<Record<ActionHistoryKey, number | undefined>>(
      LocalStorageKey.ActionHistory
    );
  }

  getActionHistory(key: ActionHistoryKey) {
    return this.getActionHistories()?.[key];
  }

  setActionHistory(key: ActionHistoryKey) {
    const histories = this.getActionHistories();
    this.setItem(LocalStorageKey.ActionHistory, {
      ...histories,
      [key]: Date.now(),
    });
  }

  resetActionHistory(key: ActionHistoryKey) {
    const histories = this.getActionHistories();
    this.setItem(LocalStorageKey.ActionHistory, omit(histories, [key]));
  }

  getActionCounts() {
    return this.getItem<Record<ActionCountKey, number | undefined>>(LocalStorageKey.ActionCount);
  }

  getActionCount(key: ActionCountKey) {
    return this.getActionCounts()?.[key] ?? 0;
  }

  addActionCount(key: ActionCountKey) {
    const counts = this.getActionCounts();

    this.setItem(LocalStorageKey.ActionCount, {
      ...counts,
      [key]: Math.min((counts?.[key] ?? 0) + 1, Number.MAX_SAFE_INTEGER),
    });
  }
}

export const realtyLocalStorage = new LocalStorage();
