import FieldLogger from '@/routes/articles/-components/FieldLogger';
import { useArticleForm } from '@/routes/articles/-contexts';
import { TextField } from '@daangn/carotene';
import { useController } from 'react-hook-form';

const BuildingApprovalDate = () => {
  const { control } = useArticleForm();

  const { field, fieldState } = useController({
    control,
    name: 'buildingApprovalDate',
  });

  return (
    <div className="grid gap-6 grid-cols-2">
      <FieldLogger name="building_approval_date">
        <TextField
          {...field}
          type="date"
          label="사용승인일 (연식)"
          invalid={!!fieldState.error}
          errorMessage={fieldState.error?.message}
        />
      </FieldLogger>
    </div>
  );
};

export default BuildingApprovalDate;
