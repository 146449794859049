import { SearchResultRenderer } from '@/components/SearchResult';
import TextRemoveButton from '@/components/TextRemoveButton';
import { CorFormState } from '@/hooks/useCheckWritableAddress';
import SearchCorAddressResult from '@/routes/articles/-components/SearchCorAddressResult';
import { useArticleForm } from '@/routes/articles/-contexts';
import { useSearch } from '@daangn/realty-react/hooks';
import {
  buildingLedgerTitleToArticleForm,
  findCorAddressMatchingBuildingLedgerTitle,
  getJibunAddress,
} from '@daangn/realty-sdk/services';
import { IconSearchRegular } from '@seed-design/react-icon';
import { useState } from 'react';
import { useController } from 'react-hook-form';
import { format } from 'date-fns';
import { DateFormat } from '@daangn/realty-sdk';
import logger from '@/utils/Logger';
import FieldLogger from '@/routes/articles/-components/FieldLogger';
import {
  SalesTypeEnum,
  type ArticleOptionNameEnum,
  type ArticleOptionValueEnum,
} from '@/types/schemaEnums';
import { union } from 'lodash-es';
import { captureException } from '@sentry/react';
import { Badge, TextField } from '@daangn/carotene';
import realtyClient from '@/api/realtyClient';

const AddressField = () => {
  const { control, watch, setValue } = useArticleForm();
  const address = useController({ control, name: 'address' });
  const fullAddress = useController({ control, name: 'fullAddress' });
  const coordinate = useController({ control, name: 'coordinate' });
  const corRealtyId = useController({ control, name: 'corRealtyId' });

  const { input, query, setInput, reset } = useSearch('');
  const [isSearchMode, setIsSearchMode] = useState(false);

  const handleSelectAddress = (corAddress: CorFormState) => {
    logger.click({
      name: 'select_address',
      params: {
        cor_address: corAddress,
      },
    });

    address.field.onChange(corAddress.address);
    fullAddress.field.onChange(corAddress.fullAddress);
    coordinate.field.onChange(corAddress.coordinate);
    corRealtyId.field.onChange(corAddress.corRealtyId);

    reset();
    corAddress.fullAddress && setInput(corAddress.address);
    setIsSearchMode(false);

    if (corAddress.address) {
      realtyClient
        .getBuildingLedgerTitle(corAddress.address)
        .then((result) => findCorAddressMatchingBuildingLedgerTitle(corAddress.address, result))
        .then((ledgerTitle) => {
          if (ledgerTitle) {
            const { buildingApprovalDate, options, topFloor } =
              buildingLedgerTitleToArticleForm(ledgerTitle);
            const prevOptions = watch('options');

            buildingApprovalDate &&
              setValue('buildingApprovalDate', format(buildingApprovalDate, DateFormat.DateScalar));
            options &&
              setValue(
                'options',
                union(
                  prevOptions,
                  options as {
                    name: ArticleOptionNameEnum;
                    value: ArticleOptionValueEnum;
                  }[]
                )
              );
            topFloor && setValue('topFloor', topFloor);
          }
        })
        .catch((e) => {
          captureException(e);
        });
    }
  };

  const handleDelete = () => {
    address.field.onChange(null);
    fullAddress.field.onChange(null);
    coordinate.field.onChange(null);
    corRealtyId.field.onChange(null);

    reset();
  };

  const isSearching = input && isSearchMode;
  const fullAddressValue = watch('fullAddress');

  return (
    <div className="flex flex-col gap-4">
      <FieldLogger name="address">
        <TextField
          {...address.field}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          label="매물 주소"
          placeholder="주소를 입력하세요"
          width="100%"
          onFocus={() => setIsSearchMode(true)}
          invalid={!!address.fieldState.error}
          errorMessage={address.fieldState.error?.message}
          suffix={
            input.length === 0 ? (
              <IconSearchRegular width={22} height={22} />
            ) : (
              <TextRemoveButton onClickDelete={handleDelete} />
            )
          }
        />
      </FieldLogger>
      {isSearching && (
        <SearchResultRenderer input={input} query={query} minInputLength={2}>
          <SearchCorAddressResult
            query={query}
            onSelectItem={handleSelectAddress}
            // 토지 거르기
            selectedSalesType={SalesTypeEnum.Apart}
          />
        </SearchResultRenderer>
      )}
      {fullAddressValue && (
        <div className="text-fg-neutral bg-bg-layerDefault px-4 py-3 body-small-default rounded-md flex flex-col gap-1">
          <div>{fullAddressValue}</div>
          {getJibunAddress(fullAddressValue) && (
            <div className="flex gap-1 items-center rounded-sm">
              <Badge size="small" tone="neutral">
                지 번
              </Badge>
              <div>{getJibunAddress(fullAddressValue)}</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AddressField;
