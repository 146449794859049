import * as React from 'react';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import classNames from 'classnames';

const ButtonRadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Root
      className={classNames('flex  overflow-hidden', className)}
      {...props}
      ref={ref}
    />
  );
});
ButtonRadioGroup.displayName = RadioGroupPrimitive.Root.displayName;

const ButtonRadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>
>(({ className, children, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Item
      ref={ref}
      className={classNames(
        'h-full inline-block border border-solid border-bg-neutralHover first-of-type:rounded-s-md last-of-type:rounded-e-md mr-[-1px] last-of-type:mr-0 px-3 py-1 data-[state=checked]:bg-bg-neutralInvertedHover data-[state=checked]:border-gray700 data-[state=checked]:text-fg-neutralInverted body-medium-default data-[disabled]:text-fg-disabled',
        className
      )}
      {...props}
    >
      <div className="flex items-center justify-center h-full w-full">{children}</div>
    </RadioGroupPrimitive.Item>
  );
});
ButtonRadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName;

export { ButtonRadioGroup, ButtonRadioGroupItem };
