import { LabelIndicator, LabelOption, LabelRoot, LabelText } from '@/components/base/Label';

import type { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  indicator?: React.ReactNode;
  option?: React.ReactNode;
}>;

export const Label = ({ children, indicator, option }: Props) => {
  return (
    <LabelRoot>
      <LabelText>{children}</LabelText>
      {indicator && <LabelIndicator>{indicator}</LabelIndicator>}
      {option && <LabelOption>{option}</LabelOption>}
    </LabelRoot>
  );
};
