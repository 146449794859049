import { GraphQLError, RelayNetworkError } from '@/relay/types';

export function getRelayErrorMessage(error: RelayNetworkError) {
  const errors = error?.source?.errors ?? [];

  if (errors.length === 0) {
    return null;
  }

  return errors[0]?.message ?? errors[0]?.extensions?.message;
}

export const getRelayNetworkErrorHandler =
  (fallbackMessage = '일시적인 오류가 발생했어요') =>
  (networkError: RelayNetworkError) => {
    const message = getRelayErrorMessage(networkError);
    window.alert(message ?? fallbackMessage);
  };

/* eslint-disable @typescript-eslint/naming-convention */
type PopupError = {
  __typename: 'PopupError';
  popupError: {
    message: string;
  };
};
type ToastError = {
  __typename: 'ToastError';
  toastError: {
    message: string;
  };
};
type OtherResult = {
  __typename: `%other`;
};

const defaultToastHandler = (toastError: ToastError) => window.alert(toastError.toastError.message);
const defaultPopupHandler = (popupError: PopupError) => window.alert(popupError.popupError.message);

type FilterResult<Result extends { __typename: string }> = Result extends ToastError
  ? never
  : Result extends PopupError
    ? never
    : Result extends OtherResult
      ? never
      : Result;

export function handleMutationResponse<ValidResult extends { __typename: string }>(
  response: PopupError | ToastError | OtherResult | ValidResult,
  handler: {
    onResult: (result: FilterResult<ValidResult>) => void;
    onPopupError?: (popupError: PopupError) => void;
    onToastError?: (toastError: ToastError) => void;
  }
) {
  if (response.__typename === 'PopupError') {
    (handler.onPopupError || defaultPopupHandler)?.(response as PopupError);
  } else if (response.__typename === 'ToastError') {
    (handler.onToastError || defaultToastHandler)?.(response as ToastError);
  } else {
    handler.onResult(response as FilterResult<ValidResult>);
  }
}

/* eslint-enable @typescript-eslint/naming-convention */

// code 존재시 서비스 레이어 레벨 에러
// https://daangn.slack.com/archives/C019D3X6CGZ/p1671074876092999
export const checkIsServiceError = (error?: GraphQLError) => {
  return error?.extensions?.code;
};

export const checkIsRelayNetworkError = (error?: Error): error is RelayNetworkError => {
  return !!error?.name?.match('RelayNetwork');
};
