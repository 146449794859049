import sha256 from 'sha256';
import { commitMutation, graphql } from 'relay-runtime';
import MainEnvironment from '@/relay/environment';
import { bigpictureRequestUploadMutation } from '@/__generated__/bigpictureRequestUploadMutation.graphql';
import axios from 'axios';

export default {
  requestUploadUrl: async ({ file, image }: { file: File; image: HTMLCanvasElement }) => {
    return new Promise<{
      originalId: string;
      uploadUrl: string;
      thumbnailUrl: string;
    }>((resolve, reject) =>
      commitMutation<bigpictureRequestUploadMutation>(MainEnvironment, {
        mutation: graphql`
          mutation bigpictureRequestUploadMutation($input: RequestBigPictureImageUploadInput!) {
            requestBigPictureImageUpload(input: $input) {
              __typename
              ... on BigPictureImageUploadInfo {
                originalId
                uploadUrl
                thumbnailUrl
              }
              ... on ToastError {
                toastError {
                  message
                }
              }
            }
          }
        `,
        variables: {
          input: {
            type: 'article',
            width: image.width,
            height: image.height,
            contentType: file.type,
            filepath: `realty/articles/${sha256(file.name)}_${Date.now()}.${file.type.replace(
              'image/',
              ''
            )}`,
          },
        },
        onCompleted: (response) => {
          switch (response.requestBigPictureImageUpload.__typename) {
            case 'ToastError': {
              reject(response.requestBigPictureImageUpload.toastError.message);
              break;
            }
            case 'BigPictureImageUploadInfo': {
              resolve(response.requestBigPictureImageUpload);
              break;
            }
            default: {
              reject('Unknown Typename for requestBigPictureImageUpload');
              break;
            }
          }
        },
        onError: (error) => {
          reject(error);
        },
      })
    );
  },
  uploadFileToS3: async ({
    url,
    blob,
    file,
    imageId,
  }: {
    url: string;
    blob: Blob;
    file: File;
    imageId: string;
  }): Promise<any> => {
    const { data } = await axios.put(url, blob, {
      headers: {
        'Content-Type': file.type,
        'x-amz-meta-id': imageId,
      },
    });

    return data;
  },
};
