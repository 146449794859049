/**
 * @generated SignedSource<<c655528179908418956af7779a6672ff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ArticleBuildingOrientationEnum = "EAST_FACING" | "NORTH_EAST_FACING" | "NORTH_FACING" | "NORTH_WEST_FACING" | "SOUTH_EAST_FACING" | "SOUTH_FACING" | "SOUTH_WEST_FACING" | "WEST_FACING";
export type ArticleManageCostOptionEnum = "BOILER" | "CLEANING" | "ELECTRIC" | "ELEVATOR" | "GAS" | "INTERNET" | "PARKING" | "TV" | "WATERWORKS";
export type ArticleOptionNameEnum = "AIRCON" | "BED" | "ELEC_RANGE" | "ELEVATOR" | "FRIDGE" | "GAS_RANGE" | "INDUCTION" | "LOFT" | "MORTGAGE" | "PARKING" | "PET" | "ROOFTOP" | "WASHER";
export type ArticleOptionValueEnum = "DONT_KNOW" | "NO" | "YES";
export type ArticleQualitativeItemEnum = "AFFORDABLE_PRICE" | "CLEAN_CONDITION" | "CONVENIENT_TRANSPORT" | "GOOD_SOUNDPROOFING" | "GOOD_SUNLIGHT" | "MANY_AMENITIES" | "NICE_VIEW" | "NO_PESTS" | "SAFE_FOR_SOLO" | "WITH_TERRACE";
export type BuildingUsageEnum = "ACCOMMODATION_FACILITY" | "CULTURAL_AND_ASSEMBLY_FACILITIES" | "OFFICE_FACILITY" | "PUBLIC_HOUSING" | "SINGLE_FAMILY_HOUSING" | "TYPE_1_NEIGHBORHOOD_LIVING_FACILITY" | "TYPE_2_NEIGHBORHOOD_LIVING_FACILITY";
export type ManageCostPayOptionEnum = "FIXED" | "NEED_TO_CHECK" | "NONE" | "USED";
export type SalesTypeEnum = "APART" | "ETC" | "OFFICETEL" | "OPEN_ONE_ROOM" | "SPLIT_ONE_ROOM" | "STORE" | "TWO_ROOM";
export type TradeTypeEnum = "BORROW" | "BUY" | "MONTH" | "SHORT" | "YEAR";
export type WriterTypeEnum = "BROKER" | "LESSOR" | "TENANT";
export type CreateArticleInputV3 = {
  address: string;
  addressDong?: string | null | undefined;
  addressHo?: string | null | undefined;
  addressInfo?: string | null | undefined;
  area?: string | null | undefined;
  availableParkingSpots?: number | null | undefined;
  availableParkingSpotsV2?: number | null | undefined;
  bathroomCnt?: number | null | undefined;
  bizPostId?: string | null | undefined;
  bizProfileOriginalId?: string | null | undefined;
  brokerOriginalId?: string | null | undefined;
  brokerTelephoneNumber?: string | null | undefined;
  buildingApprovalDate?: string | null | undefined;
  buildingName?: string | null | undefined;
  buildingOrientation?: ArticleBuildingOrientationEnum | null | undefined;
  buildingUsage?: BuildingUsageEnum | null | undefined;
  communityArticleOriginalId?: string | null | undefined;
  contactTargetPhoneNumber?: string | null | undefined;
  content: string;
  coordinate: CoordinateInput;
  corRealtyId?: string | null | undefined;
  etcManageCost?: number | null | undefined;
  etcSalesType?: string | null | undefined;
  excludeManageCostOption?: ReadonlyArray<ArticleManageCostOptionEnum> | null | undefined;
  floor?: string | null | undefined;
  floorPlanImageIds?: ReadonlyArray<string> | null | undefined;
  fullAddress?: string | null | undefined;
  hoianArticleId?: string | null | undefined;
  imageIds: ReadonlyArray<string>;
  includeManageCostOption?: ReadonlyArray<ArticleManageCostOptionDetailInput> | null | undefined;
  isUnknownManageCost?: boolean | null | undefined;
  isUrgent?: boolean | null | undefined;
  manageCost?: number | null | undefined;
  manageCostDescription?: string | null | undefined;
  moveInDate?: string | null | undefined;
  options: ReadonlyArray<ArticleOptionInput>;
  originalBigStreamIds?: ReadonlyArray<string> | null | undefined;
  originalVideoIds?: ReadonlyArray<string> | null | undefined;
  premiumMoney?: number | null | undefined;
  premiumMoneyDescription?: string | null | undefined;
  qualitativeItems?: ReadonlyArray<ArticleQualitativeItemEnum> | null | undefined;
  roomCnt?: number | null | undefined;
  salesType: SalesTypeEnum;
  supplyArea?: string | null | undefined;
  topFloor?: string | null | undefined;
  trades: ReadonlyArray<ArticleTradeInputV2>;
  writerCoordinate?: CoordinateInput | null | undefined;
  writerType: WriterTypeEnum;
};
export type CoordinateInput = {
  lat: string;
  lon: string;
};
export type ArticleManageCostOptionDetailInput = {
  fixedCost?: number | null | undefined;
  option: ArticleManageCostOptionEnum;
  payOption?: ManageCostPayOptionEnum | null | undefined;
};
export type ArticleOptionInput = {
  name: ArticleOptionNameEnum;
  value: ArticleOptionValueEnum;
};
export type ArticleTradeInputV2 = {
  adjustable?: boolean | null | undefined;
  description?: string | null | undefined;
  manageCost?: number | null | undefined;
  manageCostDescription?: string | null | undefined;
  monthlyPay?: number | null | undefined;
  preferred: boolean;
  price: number;
  tradeType: TradeTypeEnum;
};
export type newPageMutation$variables = {
  input: CreateArticleInputV3;
};
export type newPageMutation$data = {
  readonly createArticleV3: {
    readonly __typename: "CreateArticleOutput_Result";
    readonly result: {
      readonly article: {
        readonly id: string;
        readonly originalId: string;
      };
    };
  } | {
    readonly __typename: "ToastError";
    readonly toastError: {
      readonly message: string;
    };
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type newPageMutation = {
  response: newPageMutation$data;
  variables: newPageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "createArticleV3",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CreateArticleOutput_ResultPayload",
            "kind": "LinkedField",
            "name": "result",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Article",
                "kind": "LinkedField",
                "name": "article",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "originalId",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "CreateArticleOutput_Result",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ToastErrorPayload",
            "kind": "LinkedField",
            "name": "toastError",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "ToastError",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "newPageMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "newPageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7f70cb016c560386eba112d69f14ae2c",
    "id": null,
    "metadata": {},
    "name": "newPageMutation",
    "operationKind": "mutation",
    "text": "mutation newPageMutation(\n  $input: CreateArticleInputV3!\n) {\n  createArticleV3(input: $input) {\n    __typename\n    ... on CreateArticleOutput_Result {\n      result {\n        article {\n          id\n          originalId\n        }\n      }\n    }\n    ... on ToastError {\n      toastError {\n        message\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8ddb5db8953006690db69eff5f05db65";

export default node;
