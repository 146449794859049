import logger from '@/utils/Logger';
import { Link } from '@tanstack/react-router';
import { Button } from '@daangn/carotene';
import useAppQuery from '@/hooks/useAppQuery';
import { config } from '@/config';

const Header = () => {
  const { viewer } = useAppQuery();

  const handleClickChat = () => {
    logger.click({ name: 'click_gnb_chat' });
  };

  return (
    <header className="h-14 fixed top-0 left-0 right-0 bottom-0 border-b border-bg-neutralHover bg-gray00 z-50">
      <div className="flex h-full items-center max-w-screen-md mx-5 md:mx-auto">
        <Link
          to="/articles/new"
          className="[&.active]:font-bold"
          onClick={() => logger.click({ name: 'header_logo' })}
        >
          <img src="https://assetstorage.krrt.io/1138369647032855545/d6f6145d-a9ff-4b7f-9384-d8d8511baa6a/width=57,height=32.svg" />
        </Link>
        <div className="spacer" />
        {!!viewer && (
          <a href={config.chatUrl} onClick={handleClickChat} target="_blank">
            <Button variant="brandWeak" size="medium">
              PC에서 채팅하기
            </Button>
          </a>
        )}
      </div>
    </header>
  );
};

export default Header;
