/**
 * @generated SignedSource<<7d4c9d1f7b68e098c13bf7ceb25eb28b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CheckIros_IrosAlarmMutation$variables = Record<PropertyKey, never>;
export type CheckIros_IrosAlarmMutation$data = {
  readonly setIrosMaintenanceFinishAlarm: {
    readonly __typename: "SetIrosMaintenanceFinishAlarmOutput_Result";
    readonly result: boolean;
  } | {
    readonly __typename: "ToastError";
    readonly toastError: {
      readonly message: string;
    };
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type CheckIros_IrosAlarmMutation = {
  response: CheckIros_IrosAlarmMutation$data;
  variables: CheckIros_IrosAlarmMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": null,
    "kind": "LinkedField",
    "name": "setIrosMaintenanceFinishAlarm",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "result",
            "storageKey": null
          }
        ],
        "type": "SetIrosMaintenanceFinishAlarmOutput_Result",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ToastErrorPayload",
            "kind": "LinkedField",
            "name": "toastError",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "ToastError",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CheckIros_IrosAlarmMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CheckIros_IrosAlarmMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "8099d68b409668d53baa54ee04e0304a",
    "id": null,
    "metadata": {},
    "name": "CheckIros_IrosAlarmMutation",
    "operationKind": "mutation",
    "text": "mutation CheckIros_IrosAlarmMutation {\n  setIrosMaintenanceFinishAlarm {\n    __typename\n    ... on SetIrosMaintenanceFinishAlarmOutput_Result {\n      result\n    }\n    ... on ToastError {\n      toastError {\n        message\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7e0a8d2feee73b7a6cec13e4e0b21298";

export default node;
