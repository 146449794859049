import logger from '@/utils/Logger';

type Props = {
  name: string;
  children: React.ReactNode;
  eventParams?: Record<string, unknown>;
};

const FieldLogger = ({ name, children, eventParams }: Props) => {
  const handleClick = () => {
    logger.click({
      name: `${name}_field`,
      params: eventParams,
    });
  };

  return (
    <div className="w-full" onClickCapture={handleClick}>
      {children}
    </div>
  );
};

export default FieldLogger;
