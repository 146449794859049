import bigpicture from '@/api/bigpicture';
import loadImage, { MetaData } from 'blueimp-load-image';

export const uploadImage = async ({
  blob,
  file,
  image,
}: {
  blob: Blob;
  file: File;
  image: HTMLCanvasElement;
}) => {
  const { uploadUrl, originalId, thumbnailUrl } = await bigpicture.requestUploadUrl({
    file,
    image,
  });

  await bigpicture.uploadFileToS3({
    url: uploadUrl,
    blob,
    file,
    imageId: originalId,
  });

  return { id: originalId, thumbnail: thumbnailUrl };
};

export const uploadImages = async (images: HTMLCanvasElement[], files: File[]) => {
  const uploadedImages = images.map((image, i) => {
    const file = files[i];

    return new Promise<{ id: string; thumbnail: string }>((resolve, reject) => {
      const onReceiveBlob = (blob: Blob | null) => {
        if (!blob) {
          return reject('[uploadImages] image blob 이 존재하지 않음');
        }

        uploadImage({ blob, file: file, image })
          .then((res) => {
            resolve({
              id: res.id,
              thumbnail: res.thumbnail,
            });
          })
          .catch((err) => reject(err));
      };

      image.toBlob(onReceiveBlob, file.type);
    });
  });

  const result = await Promise.allSettled(uploadedImages);

  return result.map((res) => (res.status === 'fulfilled' ? res.value : null));
};

export type LoadImageResult = { image: HTMLCanvasElement } & MetaData;

export const readImage = async (file: File) => {
  const data = await loadImage(file, {
    maxWidth: 1920,
    maxHeight: 1920,
    canvas: true,
  });
  return data as LoadImageResult;
};

export const validateImagesFileType = (files: File[]) => {
  return files.every((file) => file && /^image\/(png|jpg|jpeg)/.test(file.type));
};
