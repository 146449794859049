import { CookieName } from '@/constants/cookie';
import Cookies from 'js-cookie';

export const getAuthTokens = () => {
  const accessToken =
    Cookies.get(CookieName.AccessToken) ?? Cookies.get(CookieName.KarrotAccessToken);
  const refreshToken =
    Cookies.get(CookieName.RefreshToken) ?? Cookies.get(CookieName.KarrotRefreshToken);

  return { accessToken, refreshToken };
};

export const setAuthTokens = (accessToken: string, refreshToken: string) => {
  if (accessToken && refreshToken) {
    Cookies.set(CookieName.AccessToken, accessToken, {
      path: '/',
      domain: window.location.hostname,
    });
    Cookies.set(CookieName.RefreshToken, refreshToken, {
      path: '/',
      domain: window.location.hostname,
    });

    Cookies.set(CookieName.KarrotAccessToken, accessToken, {
      path: '/',
      domain: '.daangn.com',
      sameSite: 'strict',
    });
    Cookies.set(CookieName.KarrotRefreshToken, refreshToken, {
      path: '/',
      domain: '.daangn.com',
      sameSite: 'strict',
    });
  }
};

export const removeAuthTokens = () => {
  Cookies.remove(CookieName.AccessToken, {
    path: '/',
    domain: window.location.hostname,
  });
  Cookies.remove(CookieName.RefreshToken, {
    path: '/',
    domain: window.location.hostname,
  });

  Cookies.remove(CookieName.AccessToken, {
    path: '/',
    domain: '.daangn.com',
    sameSite: 'strict',
  });
  Cookies.remove(CookieName.RefreshToken, {
    path: '/',
    domain: '.daangn.com',
    sameSite: 'strict',
  });
};
