import { IconRemoveCircleFill } from '@seed-design/react-icon';

type Props = {
  onClickDelete: () => void;
};

const TextRemoveButton = ({ onClickDelete }: Props) => {
  return (
    <button
      className="p-0 h-full flex items-center justify-center *:text-fg-neutral"
      tabIndex={-1}
      type="button"
      onClick={onClickDelete}
    >
      <IconRemoveCircleFill width={22} height={22} />
    </button>
  );
};

export default TextRemoveButton;
