import BlockStyleLoader from '@/components/BlockStyleLoader';
import { InfiniteLoader } from '@daangn/realty-react/components';

type Props = {
  hasNext: boolean;
  onLoadMore: () => void;
};

const InfiniteLoading = (props: Props) => {
  return (
    <InfiniteLoader {...props}>
      <BlockStyleLoader />
    </InfiniteLoader>
  );
};

export default InfiniteLoading;
