export default class CommonStorage<T extends { [key: string]: string }> {
  private prefix: string;
  private storage: Storage;

  constructor(prefix: string, storage: Storage) {
    this.prefix = prefix;
    this.storage = storage;
  }

  protected getKey(key: T[keyof T]) {
    return `${this.prefix}#${key}`;
  }

  protected setItem<Value>(key: T[keyof T], value: Value): void {
    this.storage.setItem(this.getKey(key), JSON.stringify(value));
  }

  protected getItem<Value>(key: T[keyof T]): Value | null {
    const item = this.storage.getItem(this.getKey(key));

    return item ? JSON.parse(item) : null;
  }

  protected removeItem(key: T[keyof T]): void {
    this.storage.removeItem(this.getKey(key));
  }
}
