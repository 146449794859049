import FieldLogger from '@/routes/articles/-components/FieldLogger';
import { useArticleForm } from '@/routes/articles/-contexts';
import { MultilineTextField } from '@/components/ui/MultilineTextField';
import { useController } from 'react-hook-form';

const ContentField = () => {
  const { control } = useArticleForm();
  const { field, fieldState } = useController({ control, name: 'content' });

  return (
    <FieldLogger name="content">
      <MultilineTextField
        {...field}
        indicator="(선택)"
        minHeight={200}
        label="매물 설명"
        isInvalid={fieldState.invalid}
        errorMessage={fieldState.error?.message}
      />
    </FieldLogger>
  );
};

export default ContentField;
