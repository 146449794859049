import DeleteButton from '@/components/DeleteButton';
import useVideoUpload from '@/hooks/useVideoUpload';
import Field from '@/routes/articles/-components/Field';
import FieldLogger from '@/routes/articles/-components/FieldLogger';
import { useArticleForm } from '@/routes/articles/-contexts';
import { trimVideoName } from '@/utils/video';
import { Button } from '@daangn/carotene';
import { IconAddRegular } from '@seed-design/react-icon';
import { useController } from 'react-hook-form';

const VideoField = () => {
  const { control } = useArticleForm();
  const videos = useController({ control, name: 'videos' });
  const video = videos.field.value?.[0];
  const { handleInputFileChange, uploadState } = useVideoUpload({
    videos: videos.field.value ?? [],
    onChangeVideos: (v) => {
      videos.field.onChange(v);
    },
  });

  const status = uploadState.loading ? 'loading' : video ? 'uploaded' : 'none';

  const handleClickDeleteVideo = () => {
    videos.field.onChange([]);
  };

  return (
    <Field label="동영상" {...videos.fieldState}>
      {status === 'none' && (
        <FieldLogger name="video">
          <label htmlFor="video" className="inline-block cursor-pointer *:pointer-events-none">
            <Button
              type="button"
              variant="neutral"
              icon={<IconAddRegular width={12} height={12} />}
            >
              동영상 추가
            </Button>
            <input
              id="video"
              type="file"
              accept="video/*"
              hidden
              multiple
              onChange={handleInputFileChange}
            />
          </label>
        </FieldLogger>
      )}
      {status === 'loading' && (
        <Button type="button" size="medium" variant="neutral">
          <span>동영상 업로드 중 ({uploadState.progress.toFixed(0)}%)</span>
        </Button>
      )}
      {status === 'uploaded' && (
        <div className="relative w-fit">
          <Button type="button" size="medium" variant="neutral">
            {trimVideoName(video?.filename || '')}
          </Button>
          <div className="absolute top-[-12px] right-[-12px]">
            <DeleteButton onClick={handleClickDeleteVideo} />
          </div>
        </div>
      )}
    </Field>
  );
};

export default VideoField;
