/**
 * @generated SignedSource<<57c6a315289cbeb011ad86dcd32359a1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ArticleBuildingOrientationEnum = "EAST_FACING" | "NORTH_EAST_FACING" | "NORTH_FACING" | "NORTH_WEST_FACING" | "SOUTH_EAST_FACING" | "SOUTH_FACING" | "SOUTH_WEST_FACING" | "WEST_FACING";
export type ArticleOptionNameEnum = "AIRCON" | "BED" | "ELEC_RANGE" | "ELEVATOR" | "FRIDGE" | "GAS_RANGE" | "INDUCTION" | "LOFT" | "MORTGAGE" | "PARKING" | "PET" | "ROOFTOP" | "WASHER";
export type SalesTypeEnum = "APART" | "ETC" | "OFFICETEL" | "OPEN_ONE_ROOM" | "SPLIT_ONE_ROOM" | "STORE" | "TWO_ROOM";
export type TradeTypeEnum = "BORROW" | "BUY" | "MONTH" | "SHORT" | "YEAR";
export type AiConversionDialogMutation$variables = {
  content: string;
};
export type AiConversionDialogMutation$data = {
  readonly generateAutoFillFormByPlainTextForBroker: {
    readonly addressInfo: string | null | undefined;
    readonly area: string | null | undefined;
    readonly availableParkingSpotsV2: number | null | undefined;
    readonly bathroomCnt: number | null | undefined;
    readonly buildingOrientation: ArticleBuildingOrientationEnum | null | undefined;
    readonly content: string | null | undefined;
    readonly floor: string | null | undefined;
    readonly manageCost: number | null | undefined;
    readonly manageCostDescription: string | null | undefined;
    readonly moveInDate: string | null | undefined;
    readonly options: ReadonlyArray<ArticleOptionNameEnum>;
    readonly roomCnt: number | null | undefined;
    readonly salesType: SalesTypeEnum | null | undefined;
    readonly topFloor: string | null | undefined;
    readonly trades: ReadonlyArray<{
      readonly description: string | null | undefined;
      readonly monthlyPay: number | null | undefined;
      readonly price: number;
      readonly tradeType: TradeTypeEnum;
    }>;
  } | null | undefined;
};
export type AiConversionDialogMutation = {
  response: AiConversionDialogMutation$data;
  variables: AiConversionDialogMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "content"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "plainText",
        "variableName": "content"
      }
    ],
    "concreteType": "AutoFillArticleForm",
    "kind": "LinkedField",
    "name": "generateAutoFillFormByPlainTextForBroker",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "addressInfo",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "area",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "availableParkingSpotsV2",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "bathroomCnt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "buildingOrientation",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "content",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "floor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "manageCost",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "manageCostDescription",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "moveInDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "options",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "roomCnt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "salesType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "topFloor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AutoFillArticleTrade",
        "kind": "LinkedField",
        "name": "trades",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "price",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "monthlyPay",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tradeType",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AiConversionDialogMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AiConversionDialogMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d509484874e97f1430bbf94222f4aea7",
    "id": null,
    "metadata": {},
    "name": "AiConversionDialogMutation",
    "operationKind": "mutation",
    "text": "mutation AiConversionDialogMutation(\n  $content: String!\n) {\n  generateAutoFillFormByPlainTextForBroker(plainText: $content) {\n    addressInfo\n    area\n    availableParkingSpotsV2\n    bathroomCnt\n    buildingOrientation\n    content\n    floor\n    manageCost\n    manageCostDescription\n    moveInDate\n    options\n    roomCnt\n    salesType\n    topFloor\n    trades {\n      description\n      price\n      monthlyPay\n      tradeType\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "37db27a34b7ee57297da999480aefc9a";

export default node;
