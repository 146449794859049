import Field from '@/routes/articles/-components/Field';
import { useArticleForm } from '@/routes/articles/-contexts';
import { ArticleOptionNameEnum, ArticleOptionValueEnum } from '@/types/schemaEnums';
import { getArticleOptionNameText, getArticleOptionValueText } from '@daangn/realty-sdk';
import { useController } from 'react-hook-form';
import { unionBy } from 'lodash-es';
import { ArticleOptionValues, RequiredArticleOptionNames } from '@/constants/article';
import { ButtonRadioGroup, ButtonRadioGroupItem } from '@/components/ButtonRadio';
import FieldLogger from '@/routes/articles/-components/FieldLogger';
import { TextField } from '@daangn/carotene';

const getOptionValues = (name: ArticleOptionNameEnum) => {
  if (name === ArticleOptionNameEnum.Parking) {
    return [ArticleOptionValueEnum.Yes, ArticleOptionValueEnum.No];
  }

  return ArticleOptionValues;
};

const RequiredOptionField = () => {
  const { control, watch, resetField } = useArticleForm();
  const { field, fieldState } = useController({
    control,
    name: 'requiredOptions',
  });
  const parkingSpots = useController({
    control,
    name: 'availableParkingSpotsV2',
  });

  const handleChangeOptionValue = (name: ArticleOptionNameEnum, value: ArticleOptionValueEnum) => {
    field.onChange(unionBy([{ name, value }], watch('requiredOptions') ?? [], 'name'));
  };

  return (
    <Field label="필수 항목" {...fieldState}>
      <div>
        {RequiredArticleOptionNames.map((name) => {
          const option = field.value?.find((option) => option.name === name);
          return (
            <div key={name} className="flex flex-col gap-3 py-5">
              <div className="flex gap-12">
                <span className="body-medium-default">
                  {getArticleOptionNameText(name, { isBroker: true })}
                </span>
                <div className="spacer" />
                <div className="flex flex-col gap-2 items-end">
                  <ButtonRadioGroup
                    name={field.name}
                    value={option?.value ?? ''}
                    onValueChange={(v) => {
                      const value = v as ArticleOptionValueEnum;
                      handleChangeOptionValue(name, value);

                      if (
                        name === ArticleOptionNameEnum.Parking &&
                        value === ArticleOptionValueEnum.No
                      ) {
                        resetField('availableParkingSpotsV2');
                      }
                    }}
                  >
                    {getOptionValues(name).map((value) => (
                      <ButtonRadioGroupItem key={value} value={value}>
                        <FieldLogger name="required_option" eventParams={{ type: value }}>
                          {getArticleOptionValueText(value)}
                        </FieldLogger>
                      </ButtonRadioGroupItem>
                    ))}
                  </ButtonRadioGroup>
                </div>
              </div>
              {name === ArticleOptionNameEnum.Parking &&
                option?.value === ArticleOptionValueEnum.Yes && (
                  <FieldLogger name="parking_spots">
                    <TextField
                      {...parkingSpots.field}
                      width="100%"
                      type="number"
                      placeholder="0"
                      suffix="대"
                      value={String(parkingSpots.field.value ?? '')}
                      onChange={(e) => {
                        parkingSpots.field.onChange(Number(e.target.value));
                      }}
                      invalid={!!parkingSpots.fieldState.error}
                      errorMessage={parkingSpots.fieldState.error?.message}
                      description="세대당 가능한 주차대수를 알려주세요."
                    />
                  </FieldLogger>
                )}
            </div>
          );
        })}
      </div>
    </Field>
  );
};

export default RequiredOptionField;
