import classNames from 'classnames';
import React, { forwardRef } from 'react';

type LabelProps = React.LabelHTMLAttributes<HTMLLabelElement>;
type DivProps = React.HTMLAttributes<HTMLDivElement>;

const LabelRoot = forwardRef<HTMLLabelElement, LabelProps>((props, ref) => {
  const { className, children, ...rest } = props;

  return (
    <label ref={ref} className={classNames('flex items-center space-x-1', className)} {...rest}>
      {children}
    </label>
  );
});

const LabelText = forwardRef<HTMLDivElement, DivProps>(({ children, className, ...rest }, ref) => (
  <div ref={ref} className={classNames('heading-xsmall', className)} {...rest}>
    {children}
  </div>
));

const LabelIndicator = forwardRef<HTMLDivElement, DivProps>(
  ({ children, className, ...rest }, ref) => (
    <div
      ref={ref}
      className={classNames('body-small-default text-fg-neutralSubtle', className)}
      {...rest}
    >
      {children}
    </div>
  )
);

const LabelOption = forwardRef<HTMLDivElement, DivProps>(
  ({ children, className, ...rest }, ref) => (
    <div ref={ref} className={classNames('flex justify-end spacer', className)} {...rest}>
      {children}
    </div>
  )
);

LabelRoot.displayName = 'Label';
LabelText.displayName = 'LabelText';
LabelIndicator.displayName = 'LabelIndicator';
LabelOption.displayName = 'LabelOption';

export { LabelRoot, LabelText, LabelIndicator, LabelOption };
