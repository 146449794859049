import { ImageType } from '@/scheme/article';
import { truthy } from '@/utils/misc';
import { nanoid } from 'nanoid';
import { ChangeEventHandler, useState } from 'react';
import { readImage, uploadImages, validateImagesFileType } from '@/utils/image';

type Params = {
  images: ImageType[];
  onChangeImages: (images: ImageType[]) => void;
  maxImageCount: number;
  events?: {
    onUploadStart?: () => void;
    onUploadEnd?: () => void;
  };
};

export type PreviewImage = {
  id: string;
  previewSrc: string;
  loadedSrc?: string;
};

const useImageUpload = ({ images, onChangeImages, maxImageCount, events }: Params) => {
  const [previewImages, setPreviewImages] = useState<PreviewImage[]>([]);
  const isUploading = previewImages.length > 0;

  const handleInputFileChange: ChangeEventHandler<HTMLInputElement> = async (e) => {
    const files = e.target.files;
    if (!files) return;
    const filesArray = Array.from(files);

    if (!validateImagesFileType(filesArray)) {
      window.alert('지원하지 않는 포맷이에요');
      return;
    }

    if (filesArray.length + images.length > maxImageCount) {
      window.alert(`${maxImageCount}장까지만 업로드 가능해요.`);
      return;
    }

    try {
      events?.onUploadStart?.();
      const uploadQueue = [];

      for (const file of files) {
        const { image } = await readImage(file);
        const imageId = nanoid();

        setPreviewImages((prev) => [
          ...prev,
          {
            id: imageId,
            previewSrc: image.toDataURL('image/jpeg', 0.3),
          },
        ]);
        const upload = uploadImages([image], [file]).then((uploadedImages) => {
          const uploadedImage = uploadedImages[0];
          if (uploadedImage) {
            setPreviewImages((prev) =>
              prev.map((prevImage) => {
                if (prevImage.id === imageId) {
                  return {
                    ...prevImage,
                    loadedSrc: uploadedImage.thumbnail,
                  };
                }
                return prevImage;
              })
            );
          }
          return uploadedImage;
        });
        uploadQueue.push(upload);
      }

      const uploadTriedImages = await Promise.all(uploadQueue);
      const successImages = uploadTriedImages.filter(truthy);
      const hasFailedImage = uploadTriedImages.length !== successImages.length;

      if (successImages.length > 0) {
        onChangeImages([...images, ...successImages]);
      }
      hasFailedImage && window.alert('업로드에 실패한 이미지가 있어요. 다시 시도해주세요.');
    } catch (err) {
      window.alert('이미지 업로드 중 에러가 발생했어요. 잠시 뒤 다시 시도해주세요.');
    } finally {
      events?.onUploadEnd?.();
      setPreviewImages([]);
      e.target.value = '';
    }
  };

  return { handleInputFileChange, previewImages, isUploading };
};

export default useImageUpload;
