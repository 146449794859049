import { Title } from '@/components/ui/Title';
import type { ComponentProps } from 'react';

type Props = ComponentProps<typeof Title> & {
  children: React.ReactNode;
};

export const Section = ({ title, description, action, children }: Props) => {
  return (
    <div className="flex flex-col gap-12">
      <Title title={title} description={description} action={action} />
      {children}
    </div>
  );
};

export const SubSection = ({ children }: Props) => {
  return <div className="flex flex-col gap-16">{children}</div>;
};

export default Section;
