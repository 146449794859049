import * as React from 'react';
import { createContext } from '@/contexts/createContext';
import classNames from 'classnames';
import DeleteButton from '@/components/DeleteButton';
import { Spinner } from '@daangn/sprout-components-spinner';
import { IconRetryRegular } from '@seed-design/react-icon';

type UploadImageContextType = {
  isUploading?: boolean;
  isError?: boolean;
};
const [UploadImageProvider] = createContext<UploadImageContextType>('UploadImage');

type UploadImageRootProps = React.HTMLAttributes<HTMLDivElement> & {
  isUploading?: boolean;
  isError?: boolean;
};
const UploadImageRoot = React.forwardRef<HTMLDivElement, UploadImageRootProps>(
  ({ isUploading, isError, className, children, ...props }, ref) => {
    return (
      <UploadImageProvider isUploading={isUploading} isError={isError}>
        <div
          ref={ref}
          className={classNames(
            'relative w-[140px] h-[140px] rounded-md border border-bg-overlay-low',
            className
          )}
          {...props}
        >
          {children}
          {isUploading && (
            <div className="absolute top-0 left-0 w-full h-full bg-bg-neutral-inverted opacity-60 rounded-md">
              <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                <Spinner variant="gray" />
              </div>
            </div>
          )}
          {isError && (
            <div className="absolute top-0 left-0 w-full h-full bg-bg-neutral-inverted opacity-60 rounded-md">
              <div className="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center text-staticWhite text-center">
                <IconRetryRegular size={40} />
                <div className="text-gray50">재시도</div>
              </div>
            </div>
          )}
        </div>
      </UploadImageProvider>
    );
  }
);

type UploadImageImageProps = React.ComponentProps<'div'>;
const UploadImageImage = ({ className, children, ...props }: UploadImageImageProps) => {
  return (
    <div
      className={classNames(
        "w-full h-full overflow-hidden rounded-md bg-[url('https://assetstorage.krrt.io/1138369647032855545/3a9161a3-0e89-40fd-860a-f4878bc7edd7/width=210,height=210.png')] bg-cover",
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};

type UploadImageDeleteButtonProps = React.ComponentProps<typeof DeleteButton>;
const UploadImageDeleteButton = ({ onClick, ...props }: UploadImageDeleteButtonProps) => {
  return (
    <div className="absolute top-[-12px] right-[-12px]">
      <DeleteButton onClick={onClick} {...props} />
    </div>
  );
};

export { UploadImageRoot, UploadImageImage, UploadImageDeleteButton };
