import { config } from '@/config';
import { CustomHeader } from '@/constants/header';
import { getAuthTokens, removeAuthTokens, setAuthTokens } from '@/utils/cookie';
import axios from 'axios';

export const realtyInstance = axios.create({
  baseURL: config.apiUrl,
});

realtyInstance.interceptors.request.use((config) => {
  const { accessToken, refreshToken } = getAuthTokens();

  if (accessToken && refreshToken) {
    config.headers[CustomHeader.AccessToken] = accessToken;
    config.headers[CustomHeader.RefreshToken] = refreshToken;
  }

  return config;
});

realtyInstance.interceptors.response.use(
  (response) => {
    const setAccessToken = response.headers[CustomHeader.SetAccessToken];
    const setRefreshToken = response.headers[CustomHeader.SetRefreshToken];

    if (setAccessToken && setRefreshToken) {
      setAuthTokens(setAccessToken, setRefreshToken);
    }

    return response;
  },
  async (error) => {
    if (error.response?.status === 401) {
      window.location.href = '/auth/login';
    }
  }
);

export default {
  loginByAccessToken: async (accessToken: string, refreshToken: string) => {
    const { data } = await realtyInstance.post<{ success: boolean }>(
      '/api/v1/auth/login-by-access-token',
      {
        accessToken,
        refreshToken,
      }
    );

    return data;
  },
  logoutByAccessToken: async () => {
    await realtyInstance.delete('/api/v1/auth/logout-by-access-token');

    removeAuthTokens();

    window.location.href = '/';
  },
};
