import * as React from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import classNames from 'classnames';

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={classNames('fixed inset-0 bg-bg-overlay z-50', className)}
    {...props}
  />
));
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <DialogPrimitive.Content
    ref={ref}
    className={classNames(
      'fixed w-[468px] top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] bg-bg-layerDefault rounded-2xl z-50 pt-8 px-7',
      className
    )}
    {...props}
  >
    {children}
  </DialogPrimitive.Content>
));
DialogContent.displayName = DialogPrimitive.Content.displayName;

const DialogHeader = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={classNames('flex flex-col space-y-2', className)} {...props} />
);
DialogHeader.displayName = 'DialogHeader';

const DialogFooter = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div>
    <div
      className={classNames('border-t border-bg-neutralPressed pt-4 pb-5 -mx-7 px-7', className)}
      {...props}
    />
  </div>
);
DialogFooter.displayName = 'DialogFooter';

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={classNames('text-2xl font-bold', className)}
    {...props}
  />
));
DialogTitle.displayName = DialogPrimitive.Title.displayName;

const DialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description ref={ref} className={classNames('text-lg', className)} {...props} />
));
DialogDescription.displayName = DialogPrimitive.Description.displayName;

const DialogButtonGroup = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={classNames('flex gap-2', className)} {...props} />
);
DialogButtonGroup.displayName = 'DialogButtonGroup';

const Dialog = {
  ...DialogPrimitive,
  Header: DialogHeader,
  Overlay: DialogOverlay,
  Content: DialogContent,
  Footer: DialogFooter,
  Title: DialogTitle,
  Description: DialogDescription,
  ButtonGroup: DialogButtonGroup,
};

export { Dialog };
