import type { ReactNode } from 'react';

type ErrorComponentProps = {
  title: ReactNode;
  description: ReactNode;
  action?: {
    label: string;
    onClick: () => void;
  };
};

const ErrorComponent = ({ title, description, action }: ErrorComponentProps) => {
  return (
    <div className="flex flex-col gap-7 text-center">
      <div className="flex flex-col gap-2">
        <h1 className="text-xl">{title}</h1>
        <p className="text-fg-neutralSubtle">{description}</p>
      </div>
      {action && (
        <div className="flex justify-center">
          <button
            className="px-5 py-3 rounded-md border border-solid border-gray300 w-[200px]"
            onClick={action.onClick}
          >
            <span className="text-fg-neutral text-lg">{action.label}</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default ErrorComponent;
