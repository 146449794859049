import Field from '@/routes/articles/-components/Field';
import { useArticleForm } from '@/routes/articles/-contexts';
import { ArticleBuildingOrientationEnum } from '@/types/schemaEnums';
import { getOrientationText } from '@daangn/realty-sdk';
import { useController } from 'react-hook-form';
import FieldLogger from '@/routes/articles/-components/FieldLogger';
import { Radio, RadioGroup } from '@daangn/carotene';

const OrientationField = () => {
  const { control } = useArticleForm();
  const { field, fieldState } = useController({
    control,
    name: 'buildingOrientation',
  });

  return (
    <Field label="매물 방향" {...fieldState}>
      <RadioGroup {...field} value={field.value ?? undefined} onValueChange={field.onChange}>
        <div className="grid grid-cols-4 gap-6 justify-between">
          {Object.values(ArticleBuildingOrientationEnum).map((type) => (
            <FieldLogger key={type} name="building_orientation" eventParams={{ type }}>
              <Radio value={type} label={getOrientationText(type)} />
            </FieldLogger>
          ))}
        </div>
      </RadioGroup>
    </Field>
  );
};

export default OrientationField;
