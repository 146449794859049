import Field from '@/routes/articles/-components/Field';
import { useArticleForm } from '@/routes/articles/-contexts';
import { useController } from 'react-hook-form';
import { BuildingUsageTypes, getBuildingUsageText } from '@daangn/realty-sdk';
import FieldLogger from '@/routes/articles/-components/FieldLogger';
import { Radio, RadioGroup } from '@daangn/carotene';

const BuildingUsageField = () => {
  const { control } = useArticleForm();
  const buildingUsage = useController({ control, name: 'buildingUsage' });

  return (
    <Field label="건축물 용도" {...buildingUsage.fieldState}>
      <RadioGroup
        {...buildingUsage.field}
        value={buildingUsage.field.value ?? undefined}
        onValueChange={buildingUsage.field.onChange}
      >
        <div className="grid grid-cols-3 justify-between gap-6">
          {BuildingUsageTypes.map((type) => (
            <FieldLogger key={type} name={`building_usage`} eventParams={{ type }}>
              <Radio value={type} label={getBuildingUsageText(type)} />
            </FieldLogger>
          ))}
        </div>
      </RadioGroup>
    </Field>
  );
};

export default BuildingUsageField;
