import { IS_LOCAL, config } from '@/config';
import logger from '@/utils/Logger';

import { init, browserTracingIntegration, replayIntegration } from '@sentry/react';

if (!IS_LOCAL) {
  init({
    dsn: config.sentryDSN,
    integrations: [
      browserTracingIntegration(),
      replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

logger.init();

// 숫자 input 스크롤 disable
document.addEventListener('wheel', (e) => {
  if (e.target instanceof HTMLInputElement && e.target.type === 'number') {
    e.target.blur();
  }
});
