import { IconRemoveCircleFill } from '@seed-design/react-icon';
import classNames from 'classnames';
import type { ButtonHTMLAttributes } from 'react';

type Props = ButtonHTMLAttributes<HTMLButtonElement>;

const DeleteButton = (props: Props) => {
  return (
    <button {...props} className={classNames('bg-transparent text-fg-neutral', props.className)}>
      <IconRemoveCircleFill width={28} height={28} />
    </button>
  );
};

export default DeleteButton;
