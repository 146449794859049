import {
  TextFieldLabel,
  TextFieldLabelIndicator,
  TextFieldLabelOption,
  TextFieldLabelText,
  TextFieldMultilineField,
  TextFieldRoot,
} from '@/components/base/TextField';
import { forwardRef, useId, type ComponentProps, type ReactNode } from 'react';

type Props = ComponentProps<typeof TextFieldMultilineField> & {
  id?: string;
  label?: ReactNode;
  indicator?: ReactNode;
  option?: ReactNode;
};

export const MultilineTextField = forwardRef<HTMLTextAreaElement, Props>(
  ({ id, label, indicator, option, ...props }, ref) => {
    const fallbackId = useId();
    const hasLabel = !!label || !!indicator || !!option;

    return (
      <TextFieldRoot id={id || fallbackId}>
        {hasLabel && (
          <TextFieldLabel>
            {label && <TextFieldLabelText>{label}</TextFieldLabelText>}
            {indicator && <TextFieldLabelIndicator>{indicator}</TextFieldLabelIndicator>}
            {option && <TextFieldLabelOption>{option}</TextFieldLabelOption>}
          </TextFieldLabel>
        )}
        <TextFieldMultilineField ref={ref} {...props} />
      </TextFieldRoot>
    );
  }
);
