import useAppQuery from '@/hooks/useAppQuery';

const BrokerInfo = () => {
  const { viewer } = useAppQuery();

  if (!viewer?.bizProfileBroker) {
    return null;
  }

  const broker = viewer.bizProfileBroker;

  return (
    <div className="flex flex-col gap-4 p-4 bg-bg-layerDefaultHover rounded-lg">
      {[
        { label: '중개소', value: broker.bizProfile.name },
        {
          label: '대표',
          value: broker.bizProfile.licenseOwnerName,
        },
        {
          label: '위치',
          value: broker.bizProfile.jibunAddress || broker.bizProfile.roadAddress,
        },
        {
          label: '사업자등록번호',
          value: broker.bizProfile.businessRegistrationNumber,
        },
        {
          label: '개설 등록번호',
          value: broker.bizProfile.licenseRegistrationNumber,
        },
      ]
        .filter((v) => !!v.value)
        .map(({ label, value }) => (
          <div className="flex gap-5 items-start" key={label}>
            <span className="min-w-[100px] body-small-default text-fg-neutralMuted">{label}</span>
            <span className="text-right body-small-strong text-fg-neutral">{value}</span>
          </div>
        ))}
    </div>
  );
};

export default BrokerInfo;
