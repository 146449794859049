import type { CheckIrosQuery } from '@/__generated__/CheckIrosQuery.graphql';
import { getRelayNetworkErrorHandler } from '@/relay/utils';
import { useEffect } from 'react';
import { graphql, useLazyLoadQuery, useMutation } from 'react-relay';

const CheckIros = () => {
  const { isIrosMaintenance } = useLazyLoadQuery<CheckIrosQuery>(
    graphql`
      query CheckIrosQuery {
        isIrosMaintenance
      }
    `,
    {}
  );
  const [setIrosMaintenanceAlarm] = useMutation(graphql`
    mutation CheckIros_IrosAlarmMutation {
      setIrosMaintenanceFinishAlarm {
        __typename
        ... on SetIrosMaintenanceFinishAlarmOutput_Result {
          result
        }
        ... on ToastError {
          toastError {
            message
          }
        }
      }
    }
  `);

  useEffect(() => {
    if (isIrosMaintenance) {
      window.alert(
        '시스템 점검으로 게시글을 작성할 수 없어요. 매물 작성이 가능해지면 당근 앱으로 알림을 보내드려요.'
      );
      setIrosMaintenanceAlarm({
        variables: {},
        onError: getRelayNetworkErrorHandler(),
      });
    }
  }, [isIrosMaintenance]);

  return null;
};

export default CheckIros;
