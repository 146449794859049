import { useRouter } from '@tanstack/react-router';
import { usePreloadedQuery } from 'react-relay';

const useAppQuery = () => {
  const router = useRouter();
  const result = usePreloadedQuery(
    router.options.context.appQuery.node,
    router.options.context.appQuery.preloaded
  );

  return result;
};

export default useAppQuery;
