import type { mainQuery$data } from '@/__generated__/mainQuery.graphql';
import { STAGE, config } from '@/config';
import { filterNil } from '@/utils/misc';
import * as amplitude from '@amplitude/analytics-browser';

type EventParams = {
  name: string;
  params?: Record<string, unknown>;
};

class Logger {
  init() {
    amplitude.init(config.amplitudeKey, {
      minIdLength: 1,
      defaultTracking: true,
      logLevel:
        STAGE !== 'production' ? amplitude.Types.LogLevel.Debug : amplitude.Types.LogLevel.Warn,
    });
  }
  setUser(user: {
    id: string;
    isDebugUser: boolean;
    bizProfileBroker?: Exclude<mainQuery$data['viewer'], null | undefined>['bizProfileBroker'];
  }) {
    const identifyEvent = new amplitude.Identify();

    amplitude.setUserId(String(user.id));
    identifyEvent.set('is_debug_user', user.isDebugUser);
    user.bizProfileBroker &&
      identifyEvent.set('biz_profile_broker', filterNil(user.bizProfileBroker));
    amplitude.identify(identifyEvent);
  }

  click = (event: EventParams) => {
    amplitude.logEvent(`[click] ${event.name}`, event.params);
  };
  impression = (event: EventParams) => {
    amplitude.logEvent(`[impression] ${event.name}`, event.params);
  };
}

const logger = new Logger();

export default logger;
