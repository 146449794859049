import axios, { AxiosProgressEvent } from 'axios';

const streamApi = {
  uploadVideo: async (
    uploadUrl: string,
    video: File,
    onUploadProgress: (progress: number) => void
  ) => {
    const formData = new FormData();
    formData.append('file', video);

    try {
      // 서버에서 uploadUrl을 통째로 내려주기 때문에 instance 사용 불가
      const result = await axios.post(uploadUrl, formData, {
        onUploadProgress: (progress: AxiosProgressEvent) => {
          onUploadProgress((progress.loaded / progress.total!) * 100);
        },
      });
      return result;
    } catch (e: unknown) {
      throw new Error('비디오 업로드에 실패하였어요.');
    }
  },
};

export default streamApi;
