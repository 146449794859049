import { MAX_QUALITATIVE_ITEM_COUNT } from '@/constants/article';
import Field from '@/routes/articles/-components/Field';
import FieldLogger from '@/routes/articles/-components/FieldLogger';
import { useArticleForm } from '@/routes/articles/-contexts';
import { ArticleQualitativeItemEnum } from '@/types/schemaEnums';
import { Checkbox } from '@daangn/carotene';
import { omit, xor } from 'lodash-es';
import { useController } from 'react-hook-form';

const QualitativeText = {
  [ArticleQualitativeItemEnum.GoodSunlight]: '햇빛이 잘 들어요.',
  [ArticleQualitativeItemEnum.ConvenientTransport]: '교통이 편리해요.',
  [ArticleQualitativeItemEnum.ManyAmenities]: '주변 편의시설이 많아요.',
  [ArticleQualitativeItemEnum.NiceView]: '전망이 좋아요.',
  [ArticleQualitativeItemEnum.NoPests]: '벌레가 없어요.',
  [ArticleQualitativeItemEnum.AffordablePrice]: '주변보다 저렴해요.',
  [ArticleQualitativeItemEnum.SafeForSolo]: '혼자 살아도 안전해요.',
  [ArticleQualitativeItemEnum.WithTerrace]: '테라스가 있어요.',
  [ArticleQualitativeItemEnum.CleanCondition]: '집 상태가 깨끗해요.',
  [ArticleQualitativeItemEnum.GoodSoundproofing]: '방음이 잘돼요.',
};

const QualitativeField = () => {
  const { control, watch } = useArticleForm();
  const { field, fieldState } = useController({
    control,
    name: 'qualitativeItems',
  });

  const handleToggle = (qualitative: ArticleQualitativeItemEnum) => {
    const qualitativeItems = watch('qualitativeItems') ?? [];

    field.onChange(xor(qualitativeItems, [qualitative]));
  };

  return (
    <Field
      label="매물 장점"
      description={`최대 ${MAX_QUALITATIVE_ITEM_COUNT}개까지 선택할 수 있어요.`}
      {...fieldState}
    >
      <div className="grid grid-cols-2 justify-between gap-6">
        {Object.values(ArticleQualitativeItemEnum).map((qualitative) => (
          <div key={qualitative}>
            <FieldLogger name="qualitative" eventParams={{ type: qualitative }}>
              <Checkbox
                {...omit(field, 'value', 'onChange')}
                onCheckedChange={() => handleToggle(qualitative)}
                checked={!!field.value?.includes(qualitative)}
                size="medium"
                label={QualitativeText[qualitative]}
              />
            </FieldLogger>
          </div>
        ))}
      </div>
    </Field>
  );
};

export default QualitativeField;
