// @ts-expect-error type definition is not exported
import { emojiPattern } from 'regex-combined-emojis';
import { toast } from 'react-hot-toast';

const emojiRegex = new RegExp(emojiPattern, 'g');

export const replaceEmoji = (text: string) => {
  const filteredValue = text.replace(emojiRegex, '');

  if (text !== filteredValue) {
    toast('이모티콘은 입력할 수 없어요.');
  }

  return filteredValue;
};
