import { ArticleOptionNameEnum, ArticleOptionValueEnum, TradeTypeEnum } from '@/types/schemaEnums';

export const RequiredArticleOptionNames = [
  ArticleOptionNameEnum.Parking,
  ArticleOptionNameEnum.Mortgage,
  ArticleOptionNameEnum.Pet,
];

export const ArticleOptionValues = [
  ArticleOptionValueEnum.DontKnow,
  ArticleOptionValueEnum.Yes,
  ArticleOptionValueEnum.No,
];

export const MAX_QUALITATIVE_ITEM_COUNT = 3;
export const MIN_IMAGE_COUNT = 3;
export const MAX_IMAGE_COUNT = 20;

export const MonthlyPayableTypes = [TradeTypeEnum.Month, TradeTypeEnum.Short, TradeTypeEnum.Year];
