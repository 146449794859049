import { useEffect, useState } from 'react';
import Lottie from 'lottie-react';

const LoadingLottie = () => {
  const [animationData, setAnimationData] = useState<unknown>();

  useEffect(() => {
    import('@/assets/lottie/loading.json').then(({ default: anim }) => {
      setAnimationData(anim);
    });
  }, []);

  return <Lottie animationData={animationData} loop autoplay style={{ width: 110 }} />;
};
export default LoadingLottie;
