import type { useSearchCorAddressResult_query$key } from '@/__generated__/useSearchCorAddressResult_query.graphql';
import {
  SearchAddressesCorInput,
  useSearchCorAddressResultQuery,
} from '__generated__/useSearchCorAddressResultQuery.graphql';
import { useSearchCorAddressResultRefetchQuery } from '__generated__/useSearchCorAddressResultRefetchQuery.graphql';
import { graphql, useLazyLoadQuery, usePaginationFragment } from 'react-relay';

const useSearchCorAddress = ({ query, dong, ho, salesType }: SearchAddressesCorInput) => {
  const queryRef = useLazyLoadQuery<useSearchCorAddressResultQuery>(
    graphql`
      query useSearchCorAddressResultQuery(
        $cursor: String
        $count: Int = 9
        $input: SearchAddressesCorInput!
      ) {
        ...useSearchCorAddressResult_query @arguments(cursor: $cursor, count: $count, input: $input)
      }
    `,
    {
      input: {
        query,
        dong,
        ho,
        salesType,
      },
    },
    { fetchPolicy: 'network-only' }
  );

  const result = usePaginationFragment<
    useSearchCorAddressResultRefetchQuery,
    useSearchCorAddressResult_query$key
  >(
    graphql`
      fragment useSearchCorAddressResult_query on Query
      @argumentDefinitions(
        count: { type: Int, defaultValue: 9 }
        cursor: { type: String }
        input: { type: "SearchAddressesCorInput!" }
      )
      @refetchable(queryName: "useSearchCorAddressResultRefetchQuery") {
        searchCorAddress(first: $count, after: $cursor, data: $input)
          @connection(key: "useSearchCorAddressResult_searchCorAddress") {
          edges {
            node {
              address
              corRealtyId
              kind
              isRentalHouse
            }
            cursor
          }
        }
      }
    `,
    queryRef
  );

  return result;
};

export default useSearchCorAddress;
