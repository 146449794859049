import { useArticleForm } from '@/routes/articles/-contexts';
import { useController } from 'react-hook-form';
import { omit } from 'lodash-es';
import FieldLogger from '@/routes/articles/-components/FieldLogger';
import { Checkbox, TextField } from '@daangn/carotene';

const ManageCostField = () => {
  const { control } = useArticleForm();

  const manageCost = useController({ control, name: 'manageCost' });
  const etcManageCost = useController({ control, name: 'etcManageCost' });
  const includeManageCostOption = useController({
    control,
    name: 'includeManageCostOption',
  });
  const isUnknownManageCost = useController({
    control,
    name: 'isUnknownManageCost',
  });

  const handleCheckIsUnknownManageCost = (isChecked: boolean) => {
    isUnknownManageCost.field.onChange(isChecked);
    if (isChecked) {
      manageCost.field.onChange(undefined);
      etcManageCost.field.onChange(undefined);
      includeManageCostOption.field.onChange(undefined);
    }
  };

  return (
    <div className="flex flex-col gap-10">
      <div className="flex flex-col gap-4">
        <div className="flex gap-6 *:grow">
          <FieldLogger name="manage_cost">
            <TextField
              {...manageCost.field}
              suffix="만원"
              label="공용 관리비"
              value={String(manageCost.field.value ?? '')}
              onChange={(e) => manageCost.field.onChange(Number(e.target.value))}
              type="number"
              placeholder="0"
              invalid={!!manageCost.fieldState.error}
              disabled={!!isUnknownManageCost.field.value}
              errorMessage={manageCost.fieldState.error?.message}
            />
          </FieldLogger>
          <FieldLogger name="etc_manage_cost">
            <TextField
              {...etcManageCost.field}
              suffix="만원"
              label="기타 관리비"
              indicator="(선택)"
              value={String(etcManageCost.field.value ?? '')}
              onChange={(e) => etcManageCost.field.onChange(Number(e.target.value))}
              type="number"
              placeholder="0"
              invalid={!!etcManageCost.fieldState.error}
              disabled={!!isUnknownManageCost.field.value}
              errorMessage={etcManageCost.fieldState.error?.message}
            />
          </FieldLogger>
        </div>
        <Checkbox
          {...omit(isUnknownManageCost.field, 'value')}
          onCheckedChange={(checked) => handleCheckIsUnknownManageCost(checked)}
          label="확인이 어려워요"
          checked={!!isUnknownManageCost.field.value}
          invalid={!!isUnknownManageCost.fieldState.error}
        />
      </div>
    </div>
  );
};

export default ManageCostField;
