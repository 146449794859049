type Truthy<T> = T extends false | '' | 0 | null | undefined ? never : T;

export const truthy = <T>(value: T): value is Truthy<T> => {
  return !!value;
};

export const filterNil = <T extends { [key: string]: unknown }>(obj: T) => {
  const entries = Object.entries(obj).filter(([, v]) => v !== null && v !== undefined);

  return Object.fromEntries(entries) as {
    [key in keyof T]-?: NonNullable<T[key]>;
  };
};
