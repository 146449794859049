import './bootstrap';
import '@daangn/carotene/reset.css';
import '@daangn/carotene/global.css';
import '@daangn/carotene/component.css';
import '@daangn/carotene/tokens';

import { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createRouter } from '@tanstack/react-router';

// Import the generated route tree
import { routeTree } from '@/routeTree.gen';
import '@/style';
import '@/index.css';
import { RelayEnvironmentProvider, fetchQuery, graphql, loadQuery } from 'react-relay';
import MainEnvironment from '@/relay/environment';
import PageError from '@/components/PageError';
import { ErrorBoundary } from '@sentry/react';
import { mainQuery } from '@/__generated__/mainQuery.graphql';

const AppQuery = graphql`
  query mainQuery {
    viewer {
      id
      isDebugUser
      originalId
      bizProfileBroker {
        id
        contactPhoneNumber
        bizProfile {
          id
          originalId
          localProfileId
          name
          businessRegistrationNumber
          licenseOwnerName
          licenseOwnerContact
          licenseRegistrationNumber
          jibunAddress
          roadAddress
          thumbImageUrl
        }
      }

      ...userLoginUser_user
    }
  }
`;

// Create a new router instance
const router = createRouter({
  routeTree,
  defaultErrorComponent: PageError,
  context: {
    appQuery: {
      node: AppQuery,
      // usePreloadedQuery 용
      preloaded: loadQuery<mainQuery>(MainEnvironment, AppQuery, {}),
      // route beforeLoad같은 곳에서 접근용 (ex)  new.tsx)
      observable: fetchQuery<mainQuery>(MainEnvironment, AppQuery, {}),
      refresh: () =>
        fetchQuery<mainQuery>(
          MainEnvironment,
          AppQuery,
          {},
          { fetchPolicy: 'network-only' }
        ).toPromise(),
    },
  },
});

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

// Render the app
const rootElement = document.getElementById('root')!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <ErrorBoundary fallback={<PageError />}>
        <RelayEnvironmentProvider environment={MainEnvironment}>
          <Suspense>
            <RouterProvider router={router} />
          </Suspense>
        </RelayEnvironmentProvider>
      </ErrorBoundary>
    </StrictMode>
  );
}
