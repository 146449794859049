import { RequestVerifyDialogMutation } from '@/__generated__/RequestVerifyDialogMutation.graphql';
import { Dialog } from '@/components/Dialog';
import { handleMutationResponse } from '@/relay/utils';
import logger from '@/utils/Logger';
import { Button, TextField } from '@daangn/carotene';
import { CommonRegex } from '@daangn/realty-sdk';
import { IconCheckFill, IconCloseFill } from '@seed-design/react-icon';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { graphql, useMutation } from 'react-relay';
import { match } from 'ts-pattern';

type Props = {
  isOpen: boolean;
  setOpen: (open: boolean) => void;
  articleId: string;
};

enum Step {
  Init = 'init',
  Request = 'request',
  Success = 'success',
  Fail = 'fail',
}

type InitStepProps = {
  onClickRequest: () => void;
};

const InitStep = ({ onClickRequest }: InitStepProps) => {
  return (
    <Dialog.Content>
      <div className="flex flex-col gap-7 pb-7">
        <Dialog.Header>
          <Dialog.Title>집주인 인증 요청</Dialog.Title>
          <Dialog.Description>
            집주인 인증을 통해 매물의 거래 완료율을 20% 이상 높여보세요.
          </Dialog.Description>
        </Dialog.Header>
        <div className="px-24 py-6 bg-bg-neutral border-bg-overlay-low rounded-[10px]">
          <img
            width={230}
            src="https://assetstorage.krrt.io/1138369647032855545/c78fc1c6-323d-40a9-adbe-ee410337f89f/width=345,height=332.6.png"
          />
        </div>
      </div>
      <Dialog.Footer>
        <Dialog.ButtonGroup>
          <div className="spacer" />
          <Dialog.Close asChild>
            <Button type="button" size="large" variant="neutral">
              다음에 할게요
            </Button>
          </Dialog.Close>
          <Button type="button" size="large" variant="brand" onClick={onClickRequest}>
            집주인 인증 요청하기
          </Button>
        </Dialog.ButtonGroup>
      </Dialog.Footer>
    </Dialog.Content>
  );
};

type RequestStepProps = {
  articleId: string;
  onRequestSuccess: () => void;
  onRequestFailed: () => void;
};

const RequestStep = ({ articleId, onRequestFailed, onRequestSuccess }: RequestStepProps) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const isValid = CommonRegex.PhoneNumber.test(phoneNumber);
  const [requestVerify, isRequestInFlight] = useMutation<RequestVerifyDialogMutation>(graphql`
    mutation RequestVerifyDialogMutation(
      $input: SendBizProfileArticleRequestIdentificationChatInput!
    ) {
      sendBizProfileArticleRequestIdentificationChat(input: $input) {
        __typename
        ... on SendBizProfileArticleRequestIdentificationChatOutput_Result {
          result {
            __typename
            isAfterFailedResponse
          }
        }
        ... on SendBizProfileArticleRequestIdentificationAlreadyTry {
          _DO_NOT_USE_THIS
        }
        ... on UserNotFoundByPhoneNumberError {
          _DO_NOT_USE_THIS
        }
        ... on ToastError {
          toastError {
            message
          }
        }
      }
    }
  `);

  const handleRequestVerify = () => {
    requestVerify({
      variables: {
        input: {
          originalArticleId: articleId,
          phoneNumber,
        },
      },
      onCompleted: ({ sendBizProfileArticleRequestIdentificationChat }) => {
        handleMutationResponse(sendBizProfileArticleRequestIdentificationChat, {
          onResult: (result) => {
            match(result)
              .with(
                {
                  __typename: 'SendBizProfileArticleRequestIdentificationChatOutput_Result',
                },
                (data) => {
                  if (data.result.isAfterFailedResponse) {
                    toast.success('집주인 인증을 다시 요청했어요.');
                  }
                  onRequestSuccess();
                }
              )
              .with({ __typename: 'UserNotFoundByPhoneNumberError' }, () => {
                onRequestFailed();
              })
              .with(
                {
                  __typename: 'SendBizProfileArticleRequestIdentificationAlreadyTry',
                },
                () => {
                  toast.error('이미 집주인 인증을 요청한 번호예요.');
                }
              )
              .exhaustive();
          },
        });
      },
    });
  };

  const handlePhoneChange = (value: string) => {
    const phoneNumber = value.replace(/\D/g, '').slice(0, 11);
    setPhoneNumber(phoneNumber);
  };

  return (
    <>
      <Dialog.Content className="flex flex-col gap-7">
        <Dialog.Header>
          <Dialog.Title>집주인 인증 요청</Dialog.Title>
          <Dialog.Description>인증 요청을 진행할 연락처를 입력해 주세요</Dialog.Description>
        </Dialog.Header>
        <div className="flex flex-col gap-3">
          <TextField
            placeholder="예) 01012341234"
            type="tel"
            value={phoneNumber}
            onChange={(e) => handlePhoneChange(e.target.value)}
            description="집주인의 핸드폰 번호만 인증이 가능해요."
          />
        </div>
        <Dialog.Footer>
          <Dialog.ButtonGroup>
            <div className="spacer" />
            <Dialog.Close asChild>
              <Button type="button" size="large" variant="neutral">
                다음에 할게요
              </Button>
            </Dialog.Close>
            <Button
              type="button"
              size="large"
              variant="brand"
              disabled={!isValid || isRequestInFlight}
              onClick={handleRequestVerify}
            >
              인증 요청하기
            </Button>
          </Dialog.ButtonGroup>
        </Dialog.Footer>
      </Dialog.Content>
    </>
  );
};

type SuccessStepProps = {
  onClose: () => void;
};

const SuccessStep = ({ onClose }: SuccessStepProps) => {
  useEffect(() => {
    const timeout = window.setTimeout(() => {
      onClose();
    }, 3000);
    return () => window.clearTimeout(timeout);
  }, []);

  return (
    <Dialog.Content className="py-10">
      <div className="flex flex-col justify-center items-center gap-4">
        <div className="w-[60px] h-[60px] rounded-full bg-bg-positiveSolid text-fg-neutralInverted flex flex-col justify-center items-center">
          <IconCheckFill size={28} />
        </div>
        <div className="flex flex-col gap-2 text-center">
          <p className="heading-large">집주인 인증 요청을 완료했어요.</p>
          <p className="text-fg-neutralMuted">인증이 완료되면 당근 앱으로 알려드려요.</p>
        </div>
      </div>
    </Dialog.Content>
  );
};

type FailStepProps = {
  onRetryRequest: () => void;
  onByPassRequest: () => void;
};

const FailStep = ({ onByPassRequest, onRetryRequest }: FailStepProps) => {
  return (
    <>
      <Dialog.Content className="pt-10">
        <div className="flex flex-col justify-center items-center gap-4 pb-12 text-center">
          <div className="w-[60px] h-[60px] rounded-full bg-bg-warningSolid text-fg-neutralInverted flex flex-col justify-center items-center">
            <IconCloseFill size={28} />
          </div>
          <div className="flex flex-col gap-2">
            <p className="heading-large">당근에 등록되지 않은 번호예요.</p>
            <p className="text-fg-neutralMuted">
              당근에 가입된 유저에게만 집주인 인증 요청이 가능해요.
            </p>
          </div>
        </div>
        <Dialog.Footer>
          <Dialog.ButtonGroup>
            <div className="spacer" />
            <Button type="button" size="large" variant="neutral" onClick={onRetryRequest}>
              번호 다시 입력하기
            </Button>
            <Button type="button" size="large" variant="brand" onClick={onByPassRequest}>
              인증 없이 게시글 등록하기
            </Button>
          </Dialog.ButtonGroup>
        </Dialog.Footer>
      </Dialog.Content>
    </>
  );
};

const RequestVerifyDialog = ({ isOpen, setOpen, articleId }: Props) => {
  const [step, setStep] = useState<Step>(Step.Init);

  const handleClickRequest = () => {
    logger.click({
      name: 'request_verify_dialog_click_request',
      params: { articleId },
    });
    setStep(Step.Request);
  };

  const handleRequestSuccess = () => {
    logger.click({
      name: 'request_verify_dialog_success',
      params: { articleId },
    });
    setStep(Step.Success);
  };

  const handleRequestFailed = () => {
    logger.click({
      name: 'request_verify_dialog_fail',
      params: { articleId },
    });
    setStep(Step.Fail);
  };

  const handleByPassRequest = () => {
    logger.click({
      name: 'request_verify_dialog_bypass',
      params: { articleId },
    });
    handleOpenChange(false);
  };

  const handleRetryRequest = () => {
    logger.click({
      name: 'request_verify_dialog_retry',
      params: { articleId },
    });
    setStep(Step.Request);
  };

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      setStep(Step.Init);
    }
    setOpen(open);
  };

  const handleClose = () => {
    logger.click({
      name: 'request_verify_dialog_close',
      params: { articleId },
    });
    handleOpenChange(false);
  };

  useEffect(() => {
    if (isOpen) {
      logger.impression({
        name: 'request_verify_dialog',
        params: { articleId, step },
      });
    }
  }, [isOpen, step]);

  return (
    <Dialog.Root open={isOpen} onOpenChange={handleOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay />
        {step === 'init' && <InitStep onClickRequest={handleClickRequest} />}
        {step === 'request' && (
          <RequestStep
            articleId={articleId}
            onRequestFailed={handleRequestFailed}
            onRequestSuccess={handleRequestSuccess}
          />
        )}
        {step === 'success' && <SuccessStep onClose={handleClose} />}
        {step === 'fail' && (
          <FailStep onByPassRequest={handleByPassRequest} onRetryRequest={handleRetryRequest} />
        )}
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default RequestVerifyDialog;
