import {
  closestCenter,
  DndContext,
  DragEndEvent,
  Modifier,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, SortingStrategy, useSortable } from '@dnd-kit/sortable';
import { CSSProperties, ReactNode } from 'react';
import { CSS } from '@dnd-kit/utilities';
import type { ImageType } from '@/scheme/article';

type ImageSortProps = {
  images: ImageType[];
  modifiers?: Modifier[];
  strategy?: SortingStrategy;
  onSortChange: (images: ImageType[]) => void;
} & Pick<ItemProps, 'children'>;

type ItemProps = {
  image: ImageType;
  index: number;
  children: (
    params: {
      image: ImageType;
      sortable: ReturnType<typeof useSortable>;
      style: CSSProperties;
    },
    index: number
  ) => ReactNode;
};

const ImageSort = ({ images, onSortChange, strategy, modifiers, children }: ImageSortProps) => {
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 5,
      },
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = images.findIndex((v) => v.id === active.id);
      const newIndex = images.findIndex((v) => v.id === over?.id);

      onSortChange(arrayMove(images, oldIndex, newIndex));
    }
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      modifiers={modifiers}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={images} strategy={strategy}>
        {images.map((image, index) => {
          return <Item key={image.id} index={index} image={image} children={children} />;
        })}
      </SortableContext>
    </DndContext>
  );
};

const Item = ({ image, index, children }: ItemProps) => {
  const sortable = useSortable({ id: image.id });
  const style = {
    transform: CSS.Transform.toString(sortable.transform),
    transition: sortable.transition,
    ...(sortable.isDragging && { zIndex: 1 }),
  };

  return <>{children({ image, sortable, style }, index)}</>;
};

export default ImageSort;
