import * as React from 'react';
import classNames from 'classnames';
import { createContext } from '@/contexts/createContext';
import {
  TextField,
  MultilineTextField,
  type SeedMultilineTextFieldProps,
  type SeedTextFieldProps,
} from '@daangn/sprout-components-text-field';
import { LabelRoot, LabelText, LabelOption, LabelIndicator } from '@/components/base/Label';

type TextFieldContextType = {
  id?: string;
};
const [TextFieldProvider, useTextFieldContext] = createContext<TextFieldContextType>('TextField');

type TextFieldRootProps = React.InputHTMLAttributes<HTMLDivElement> & {
  id?: string;
};
const TextFieldRoot = React.forwardRef<HTMLDivElement, TextFieldRootProps>((props, ref) => {
  const { id, className, ...rest } = props;

  return (
    <TextFieldProvider id={id}>
      <div ref={ref} className={classNames('space-y-3', className)} {...rest} />
    </TextFieldProvider>
  );
});

type TextFieldLabelProps = React.ComponentProps<typeof LabelRoot>;

const TextFieldLabel = React.forwardRef<HTMLLabelElement, TextFieldLabelProps>((props, ref) => {
  const { id } = useTextFieldContext('TextFieldLabel');

  return (
    <LabelRoot ref={ref} htmlFor={id} {...props}>
      {props.children}
    </LabelRoot>
  );
});

const TextFieldLabelText = LabelText;

const TextFieldLabelIndicator = LabelIndicator;
const TextFieldLabelOption = LabelOption;

type TextFieldFieldProps = Omit<
  SeedTextFieldProps,
  'requiredIndicator' | 'optionalIndicator' | 'label'
>;

const TextFieldField = React.forwardRef<HTMLInputElement, TextFieldFieldProps>(
  ({ ids, ...props }, ref) => {
    const { id } = useTextFieldContext('TextFieldField');

    return (
      <TextField
        ref={ref}
        ids={{
          input: id,
          ...ids,
        }}
        {...props}
      />
    );
  }
);

type TextFieldMultilineFieldProps = Omit<
  SeedMultilineTextFieldProps,
  'name' | 'requiredIndicator' | 'optionalIndicator' | 'label'
>;

const TextFieldMultilineField = React.forwardRef<HTMLTextAreaElement, TextFieldMultilineFieldProps>(
  ({ ids, ...props }, ref) => {
    const { id } = useTextFieldContext('TextFieldMultilineField');

    return (
      <MultilineTextField
        ref={ref}
        ids={{
          input: id,
          ...ids,
        }}
        {...props}
      />
    );
  }
);

export {
  TextFieldRoot,
  TextFieldLabel,
  TextFieldField,
  TextFieldMultilineField,
  TextFieldLabelText,
  TextFieldLabelIndicator,
  TextFieldLabelOption,
};
