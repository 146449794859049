import React, { forwardRef } from 'react';
import classNames from 'classnames';

type TitleProps = React.HTMLAttributes<HTMLDivElement> & {
  title: React.ReactNode;
  description?: React.ReactNode;
  action?: React.ReactNode;
};

const Title = forwardRef<HTMLDivElement, TitleProps>(
  ({ title, description, action, className, ...rest }, ref) => (
    <div ref={ref} className={classNames('space-y-3', className)} {...rest}>
      <div className="space-y-1.5">
        <h1 className="heading-large">{title}</h1>
        {description && <p className="body-xsmall-default text-fg-neutralSubtle">{description}</p>}
      </div>
      {action && <div>{action}</div>}
    </div>
  )
);

export { Title };
