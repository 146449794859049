import fetchGraphQL from '@/relay/fetcher';
import { logFunction } from '@daangn/realty-relay-sentry';
import {
  CacheConfig,
  Environment,
  Network,
  RecordSource,
  RequestParameters,
  Store,
  Variables,
} from 'relay-runtime';

async function fetchRelay(
  params: RequestParameters,
  variables: Variables,
  cacheConfig: CacheConfig
) {
  return fetchGraphQL(params, variables, cacheConfig);
}

export const createRelayEnvironment = () =>
  new Environment({
    log: logFunction(),
    network: Network.create(fetchRelay),
    store: new Store(new RecordSource()),
  });

const MainEnvironment = createRelayEnvironment();

export default MainEnvironment;
