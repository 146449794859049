import {
  UploadImageDeleteButton,
  UploadImageImage,
  UploadImageRoot,
} from '@/components/base/UploadImage';
import type { ComponentProps } from 'react';

import React, { forwardRef } from 'react';

type Props = ComponentProps<typeof UploadImageRoot> & {
  onClickDelete?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const UploadImage = forwardRef<HTMLDivElement, Props>(
  ({ onClickDelete, children, ...props }, ref) => {
    return (
      <UploadImageRoot ref={ref} {...props}>
        <UploadImageImage>{children}</UploadImageImage>
        <UploadImageDeleteButton onClick={onClickDelete} />
      </UploadImageRoot>
    );
  }
);

UploadImage.displayName = 'UploadImage';

export default UploadImage;
